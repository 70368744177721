export default({
  namespaced: true,
  state: {
    bookingType: ''
  },
  getters: {
    getBookingType: state => state.bookingType
  },
  mutations: {
    setBookingType: (state, data) => state.bookingType = data
  },
})

