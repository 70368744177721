<template>
  <v-list-group
    :group="group"
    :prepend-icon="item.icon"
    :sub-group="subGroup"
    append-icon="mdi-menu-down"
    :color="
      barColor !== 'rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.7)'
        ? 'white'
        : 'grey darken-1'
    "
  >
    <template v-slot:activator>
      <v-list-item-icon
        v-if="text"
        class="v-list-item__icon--text"
        v-text="computedText"
      />

      <v-list-item-avatar
        v-else-if="item.avatar"
        class="align-self-center"
        color="grey"
      >
        <!--图片-->
        <!-- <v-img :src="item.img" v-if="item.img != 'files/'" /> -->
        <newImg :fileUrl="item.img" v-if="item.img != 'files/'"></newImg>
        <!--没有头像时用名字首字符-->
        <v-avatar size="40" class="avator x-btn" v-if="item.img=='files/' || item.img == ''">
          <span class="white--text" style="font-size: 20px">
            {{ firstName }}&nbsp;{{ lastName }}
          </span>
        </v-avatar>
      </v-list-item-avatar>
      <v-list-item-icon v-else-if="item.img">
        <v-img :src="item.img" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="name" />
      </v-list-item-content>
      <!--<v-list-item-content>-->
      <!--  <v-list-item-title v-text="item.title"/>-->
      <!--</v-list-item-content>-->
    </template>

    <template v-for="(child, i) in children">
      <base-item-sub-group
        v-if="child.children"
        :key="`sub-group-${i}`"
        :item="child"
      />

      <base-item v-else :key="`item-${i}`" :item="child" text />
    </template>
  </v-list-group>
</template>

<script>
// Utilities
import kebabCase from "lodash/kebabCase";
import { mapState } from "vuex";
import newImg from "@/views/dashboard/componentsHa/img";

export default {
  name: "ItemGroup",

  inheritAttrs: false,

  props: {
    item: {
      type: Object,
      default: () => ({
        avatar: undefined,
        group: undefined,
        title: undefined,
        children: [],
      }),
    },
    subGroup: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    newImg
  },

  computed: {
    ...mapState(["barColor"]),
    children() {
      return this.item.children.map((item) => ({
        ...item,
        to: !item.to ? undefined : `${this.item.group}/${item.to}`,
      }));
    },
    computedText() {
      if (!this.item || !this.item.title) return "";

      let text = "";

      this.item.title.split(" ").forEach((val) => {
        text += val.substring(0, 1);
      });

      return text;
    },
    group() {
      return this.genGroup(this.item.children);
    },
  },
  data() {
    return {
      name: "",
      firstName: "",
      lastName: "",
      timer: null,
    };
  },
  methods: {
    genGroup(children) {
      return children
        .filter((item) => item.to)
        .map((item) => {
          const parent = item.group || this.item.group;
          let group = `${parent}/${kebabCase(item.to)}`;

          if (item.children) {
            group = `${group}|${this.genGroup(item.children)}`;
          }

          return group;
        })
        .join("|");
    },
    initData() {
      if (localStorage.userName) {
        let userName = localStorage.userName;
        let userNameArr = userName.split(" ");
        this.name = userName;
        this.firstName = userNameArr[0][0];
        this.lastName = userNameArr[1][0];
      }
    },
  },
  created() {
    // let userName = localStorage.userName;
    // let userNameArr = userName.split(" ");
    // this.name = userName;
    // this.firstName = userNameArr[0][0];
    // this.lastName = userNameArr[1][0];
    this.initData();
    let count = 0;
    let userName = localStorage.userName;
    if (!userName) {
      this.timer = setInterval(() => {
        let userName = localStorage.userName;
        if (count > 30) {
          clearInterval(this.timer);
          this.timer = null;
        }
        if (userName) {
          this.initData();
          clearInterval(this.timer);
          this.timer = null;
        } else {
          count += 1;
        }
      }, 1000);
    }
    // if (this.item.img) {
    //   this.item.img = require(`@/assets/images/desks/${this.item.img}.png`);
    // }
    // console.log(this.item);
  },
};
</script>

<style>
.v-list-group__activator p {
  margin-bottom: 0;
}
</style>
