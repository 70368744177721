// export detault{
//   goAmenities: function() {
//     this.$router.push({ path: "/desks/amenities" });
//   },
//   goBuildings: function(){
//     console.log(123)
//     this.$router.push({ path: "/desks/buildings" });
//   },
//   goFloors: function() {
//     this.$router.push({ path: "/desks/floors" });
//   },
//   goNeighborhoods: function(){
//     this.$router.push({ path: "/desks/neighborhoods" });
//   },
//   goDesks: function() {
//     this.$router.push({ path: "/desks/desks" });
//   },
// }
export default {
  // install(Vue) {
    // Vue.prototype.goAmenities = function () {
    //   this.$router.push({ path: "/desks/amenities" });
    // };
    // Vue.prototype.goBuildings = function () {
    //   vue.$router.push({ path: "/desks/buildings" });
    // };
    // Vue.prototype.goFloors = function () {
    //   this.$router.push({ path: "/desks/floors" });
    // };
    // Vue.prototype.goNeighborhoods = function () {
    //   this.$router.push({ path: "/desks/neighborhoods" });
    // };
    // Vue.prototype.goDesks = function () {
    //   this.$router.push({ path: "/desks/desks" });
    // };
  // }
}
