<template>
  <v-container id="login" fill-height tag="section">
    <v-row justify="center">
      <v-col cols="12" md="3" sm="3" xs="3"></v-col>
      <v-col
        cols="12"
        md="6"
        sm="6"
        xs="6"
        style="text-align: center"
        class="pa-0 ma-0"
      >
        <!--<img src="@/assets/images/cxAppLogo.png" style="height: 80px;margin-bottom: 20px"/>-->

        <img
          v-if="showLogoPic == 'normal'"
          :src="this.logoImg"
          class="loginImg"
          :class="{
            campus: this.client == 'CX Campus',
            clorox: this.client == 'Clorox',
          }"
        />
        <img
          v-if="showLogoPic == 'natwest'"
          :src="this.logoImg"
          class="natWestLogo"
        />
        <div v-if="showLogoPic == 'clorox'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >myDay</span
          >
        </div>
        <div v-if="showLogoPic == 'warnermedia'">
          <img
            :src="this.logoImg"
            class="natWestLogo"
          />
        </div>
        <div v-if="showLogoPic == 'aruba'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Aruba</span
          >
        </div>
        <div v-if="showLogoPic == 'hpe'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >HPE</span
          >
        </div>
        <div v-if="showLogoPic == 'adobe'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Adobe</span
          >
        </div>
        <div v-if="showLogoPic == 'lenovo'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Lenovo</span
          >
        </div>
        <div v-if="showLogoPic == 'guardant'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Guardant</span
          >
        </div>
        <div v-if="showLogoPic == 'pinterest'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Pinterest</span
          >
        </div>
        <div v-if="showLogoPic == 'appliedMaterials'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Applied Materials</span
          >
        </div>
        <div v-if="showLogoPic == 'pfizer'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Pfizer</span
          >
        </div>
        <div v-if="showLogoPic == 'rivian'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Rivian</span
          >
        </div>
        <div v-if="showLogoPic == 'doorDash'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >DoorDash</span
          >
        </div>
        <div v-if="showLogoPic == 'KLA'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >KLA</span
          >
        </div>
        <div v-if="showLogoPic == 'nutanix'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Nutanix</span
          >
        </div>
        <div v-if="showLogoPic == 'Metlife'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >MetLife</span
          >
        </div>
        <div v-if="showLogoPic == 'IMD Campus'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >IMD Campus</span
          >
        </div>
        <div v-if="showLogoPic == 'Bath University'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Bath University</span
          >
        </div>
        <div v-if="showLogoPic == 'EA'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >EA</span
          >
        </div>
        <div v-if="showLogoPic == 'Cloudflare'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Cloudflare</span
          >
        </div>
        <div v-if="showLogoPic == 'Pepsico'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >Pepsico</span
          >
        </div>
        <div v-if="showLogoPic == 'NBCU'">
          <span style="font-size: 30px; color: #000; font-weight: bold"
            >NBCU</span
          >
        </div>
        <img
          v-if="showLogoPic == 'edwards'"
          :src="this.logoImg"
          style="width: 110px"
          class="edwardsLogo"
        />
      </v-col>
      <v-col cols="12" md="3" sm="3" xs="3"></v-col>
      <v-slide-y-transition appear>
        <base-material-card
          color="success"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <h1 class="loginBar">Login</h1>

              <!--<v-btn-->
              <!--v-for="(social, i) in socials"-->
              <!--:key="i"-->
              <!--:href="social.href"-->
              <!--class="ma-1"-->
              <!--icon-->
              <!--rel="noopener"-->
              <!--target="_blank"-->
              <!--&gt;-->
              <!--<v-icon-->
              <!--v-text="social.icon"-->
              <!--/>-->
              <!--</v-btn>-->
            </div>
          </template>

          <v-card-text class="text-center">
            <!--<div class="text-center grey&#45;&#45;text body-1 font-weight-light">-->
            <!--Or Be Classical-->
            <!--</div>-->

            <!--<v-text-field-->
            <!--color="secondary"-->
            <!--label="First Name..."-->
            <!--prepend-icon="mdi-face"-->
            <!--class="mt-10"-->
            <!--/>-->
            <div v-if="showInput">
              <v-text-field
                color="secondary"
                label="Email"
                prepend-icon="mdi-email"
                v-model="email"
                @keyup.enter="login"
              />
              <div v-if="showRegionInfo == true">
                <i
                  class="iconfont icon-xinxi x-color"
                  style="font-size: 20px; float: left"
                ></i>
                <p class="regionInfo">
                  Please enter your e-mail address for regional authentication.
                  <br /><br />
                  We will only use your e-mail address to connect you to the
                  appropriate regional instance. The e-mail address will not be
                  stored and will be deleted upon initial authentication.
                </p>
              </div>
            </div>
            <div v-if="showSelectRegion">
              <div class="selectRegion">
                <p
                  v-for="r in regionData"
                  @click="selectRegionOption(r)"
                  :class="{ option: r.isCheck == true }"
                >
                  <i class="iconfont icon-network x-color"></i>
                  <span>{{ r.name }}</span>
                  <i class="iconfont icon-gou x-color" v-if="r.isCheck"></i>
                </p>
              </div>
            </div>

            <div class="checkboxContainer" v-if="isOpenCheckboxContainer">
              <v-checkbox
                v-model="checkbox"
                v-if="showCheckbox"
                @click.self
                class="myCheckbox"
                aria-label="Checkbox"
              >
              </v-checkbox>
              <!-- checkbox 右边的文字 -->
              <div v-if="showCheckbox">
                <div v-if="appLoginAgreementContent">
                  <div
                    v-dompurify-html="checkboxText"
                    class="customCheckbox"
                  ></div>
                </div>
                <div v-else class="customCheckbox">
                  <div>
                    By checking this box, you agree to
                    <span v-if="!showSelectRegion">our</span>
                    <span v-if="showSelectRegion">{{ checkRegionName }}</span>
                    <a target="_blank" :href="termsLink" @click.stop>
                      Terms of Service
                    </a>
                    . We'll handle your data according to our
                    <a target="_blank" :href="privacyLink" @click.stop>
                      Privacy Policy
                    </a>
                    <span v-if="showSelectRegion">for this region</span>
                    .
                  </div>
                </div>
              </div>
              <!--Pinterest 不显示checkbox那一段内容-->
              <p v-else style="height: 60px"></p>
            </div>

            <pages-btn large color="" depressed class="x-btn" @click="login">
              Next
            </pages-btn>
            <!--<pages-btn-->
            <!--  large-->
            <!--  color=""-->
            <!--  depressed-->
            <!--  class="v-btn&#45;&#45;text success&#45;&#45;text"-->
            <!--  @click="login"-->
            <!--&gt;-->
            <!--  Next-->
            <!--</pages-btn>-->
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
      <v-dialog v-model="newPage">
        <v-card class="dialog-window ma-0 pa-0">
          <div style="text-align: right; padding: 15px; padding-bottom: 0">
            <i
              class="iconfont icon-chahao"
              @click="newPage = false"
              style="cursor: pointer"
            ></i>
          </div>
          <v-card-text>
            <!--<vue-friendly-iframe :src="loginIframe" id="loginIframe" style="width: 100%;min-height: 430px;overflow: auto;"></vue-friendly-iframe>
            <iframe id="loginIframe" :src="loginIframe" ref="loginIframe" @load="onLoadIframe" style="width: 100%;min-height: 430px;overflow: auto;"></iframe>-->
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LoginEmail",

  components: {
    PagesBtn: () => import("./components/Btn"),
  },

  data() {
    return {
      checkboxText: "",
      showCheckbox: false,
      showLogoPic: "",
      logoImg: "",
      socials: [
        {
          href: "#",
          icon: "mdi-facebook-box",
        },
        {
          href: "#",
          icon: "mdi-twitter",
        },
        {
          href: "#",
          icon: "mdi-github-box",
        },
      ],
      email: "",
      newPage: false,
      checkbox: false,
      appLoginAgreementContent: "", //checkbox文字内容
      terms: {},
      termsLink: "",
      privacyLink: "",
      appTermsOfUseName: "",
      appPrivacyPolicyName: "",
      loginIframe: "",
      loginWin: "",
      client: "", //当前登录庸碌
      showAppPrivacyPolicy: false,
      showAppTermsOfUse: false,
      isOpenMultiRegion: false,
      multiRegionData: [],
      isOpenCheckboxContainer: false,
      regionData: [],
      showInput: true,
      showSelectRegion: false,
      checkRegionName: "",
      haveErrorInRegion: false,
      errorMessageInRegion: "",
      showRegionInfo: false,
    };
  },
  computed: {
    ...mapGetters("contactSupport", ["getContactSupport"]),
  },
  methods: {
    ...mapMutations("contactSupport", ["setContactSupport"]),
    validateEmail(email) {
      // var regex = /^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g;
      var regex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // console.log(regex.test(email.toLowerCase()));
      if (regex.test(email.toLowerCase())) {
        return true;
      } else {
        return false;
      }
      // if(email == ''){
      //   console.log('kong');
      //   return false
      // }else{
      //   let a = email.toLowerCase().match(
      //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      //   );
      //   console.log(a);
      // }
    },
    // getClient(){
    //   this.getClientData().then(res=>{
    //     this.client = res.data.Client;
    //     // console.log("client:",this.client);
    //     if(res.data.Client=='Clorox'){
    //       this.logoImg = require('@/assets/images/cloroxLogo.png')
    //     }else{
    //       this.logoImg = require('@/assets/images/cxAppLogo.png')
    //     }
    //   }).catch(err=>{
    //     console.log(err);
    //   });
    // },

    initCheckBox() {
      let config = JSON.parse(localStorage.getItem("config"));
      if (config.terms) {
        this.terms = config.terms;
        this.termsLink = config.terms.appTermsOfUseLink;
        this.privacyLink = config.terms.appPrivacyPolicyLink;
        this.appTermsOfUseName = config.terms.appTermsOfUseName;
        this.appPrivacyPolicyName = config.terms.appPrivacyPolicyName;

        this.appLoginAgreementContent = config.terms.appLoginAgreementContent;
        let text = config.terms.appLoginAgreementContent;
        console.log("text:", text);
        text = text.replace(
          /appTermsOfUse/g,
          '<a target="_blank" href="' +
            this.termsLink +
            '" @click.stop>' +
            this.appTermsOfUseName +
            "</a>"
        );
        text = text.replace(
          /appPrivacyPolicy/g,
          '<a target="_blank" href=' +
            this.privacyLink +
            " @click.stop>" +
            this.appPrivacyPolicyName +
            "</a>"
        );
        text = text.replace(/{/g, "");
        text = text.replace(/}/g, "");
        console.log("text", text);
        this.checkboxText = text;
      }
    },
    getClient() {
      this.getClientData()
        .then((res) => {
          this.client = res.data.Client;
          localStorage.setItem("client", res.data.Client);
          if (res.data.Client == "Clorox") {
            this.showLogoPic = "clorox";
            document.title = "myDay";
          } else if (res.data.Client == "NatWest") {
            this.showLogoPic = "natwest";
            this.logoImg = require("@/assets/images/NatWest-Group.jpg");
            document.title = "NatWest Group-Our Campus";
          } else if (res.data.Client == "WarnerMedia") {
            this.showLogoPic = "warnermedia";
            this.logoImg = require("@/assets/images/warnermediaLogo.png");
            document.title = "WM Red Carpet";
          } else if (res.data.Client == "Aruba") {
            this.showLogoPic = "aruba";
            document.title = "Aruba";
          } else if (
            res.data.Client == "HPE" ||
            res.data.Client == "HPE Campus"
          ) {
            this.showLogoPic = "hpe";
            document.title = "HPE";
          } else if (res.data.Client == "adobe") {
            this.showLogoPic = "adobe";
            document.title = "Adobe";
          } else if (res.data.Client == "Guardant") {
            this.showLogoPic = "guardant";
            document.title = "Guardant";
          } else if (res.data.Client == "PinterestCampus") {
            this.showLogoPic = "pinterest";
            document.title = "Pinterest";
          } else if (res.data.Client == "Lenovo Corporate") {
            this.showLogoPic = "lenovo";
            document.title = "Lenovo";
          } else if (res.data.Client == "Edwards Life Sciences") {
            this.showLogoPic = "edwards";
            this.logoImg = require("@/assets/images/EdwardsLogo.png");
            document.title = "Edwards";
          } else if (res.data.Client == "Applied Materials") {
            this.showLogoPic = "appliedMaterials";
            document.title = "Applied Materials";
          } else if (res.data.Client == "Pfizer") {
            this.showLogoPic = "pfizer";
            document.title = "Pfizer";
          } else if (res.data.Client == "Rivian") {
            this.showLogoPic = "rivian";
            document.title = "Rivian";
          } else if (res.data.Client == "DoorDash") {
            this.showLogoPic = "doorDash";
            document.title = "DoorDash";
          } else if (res.data.Client == "KLA") {
            this.showLogoPic = "KLA";
            document.title = "KLA";
          } else if (res.data.Client == "Nutanix") {
            this.showLogoPic = "nutanix";
            document.title = "Nutanix";
          } else if (res.data.Client == "Metlife") {
            this.showLogoPic = "Metlife";
            document.title = "Metlife";
          } else if (res.data.Client == "IMD Campus") {
            this.showLogoPic = "IMD Campus";
            document.title = "IMD Campus";
          } else if (res.data.Client == "Bath University") {
            this.showLogoPic = "Bath University";
            document.title = "Bath University";
          } else if (res.data.Client == "EA") {
            this.showLogoPic = "EA";
            document.title = "EA";
          } else if (res.data.Client == "Cloudflare") {
            this.showLogoPic = "Cloudflare";
            document.title = "Cloudflare";
          } else if (res.data.Client == "Pepsico") {
            this.showLogoPic = "Pepsico";
            document.title = "Pepsico";
          } else if (res.data.Client == "NBCU") {
            this.showLogoPic = "NBCU";
            document.title = "NBCU";
          } else {
            this.showLogoPic = "normal";
            this.logoImg = require("@/assets/images/cxAppLogo.png");
            // document.title = "The CXApp Smart Campus";
            document.title = "The Inpixon Smart Campus";
          }

          // PinterestCampus不展示checkbox
          if (res.data.Client == "PinterestCampus") {
            this.showCheckbox = false;
          } else {
            this.initCheckBox();
            this.showCheckbox = true;
          }
        })
        .catch((err) => {
          this.showLogoPic = "normal";
          this.logoImg = require("@/assets/images/cxAppLogo.png");
          console.log(err);
        });
    },
    getCaption(obj) {
      if (obj) {
        const index = obj.lastIndexOf("=");
        obj = obj.substring(index + 1, obj.length);
      }
      return obj;
    },
    login() {
      let _this = this;
      console.log("em:", this.email);

      let emailRegular =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!_this.validateEmail(_this.email.trim())) {
        console.log("111111", _this.validateEmail(_this.email.trim()));
        this.$confirm({
          title: "Confirmation",
          message: "Please enter a valid email address",
          show: true,
        });
        return false;
      }
      if (this.showSelectRegion) {
        if (this.checkbox === false && this.client != "PinterestCampus") {
          this.$confirm({
            title: "Confirmation",
            message:
              "You must accept the Privacy Policy and Terms of Use to proceed",
            show: true,
          });
          return false;
        }
        if (_this.checkRegionName == "") {
          this.$confirm({
            title: "Confirmation",
            message: "You must select a region",
            show: true,
          });
          return false;
        }
        let isSSO = false;
        let config = JSON.parse(localStorage.config);
        let client = localStorage.client;
        // check-email 后再检查是否是sso账号
        // if (client == "Aruba") {
        //   config.ssoSubffix.some((i) => {
        //     let sso = i.toLowerCase();
        //     if (_this.email.toLowerCase().indexOf(sso) > -1) {
        //       isSSO = true;
        //       window.location.replace(
        //         "https://sso-uat.arubanetworks.com/as/authorization.oauth2?redirect_uri=https://cx-app-aruba.appspot.com/m/sso&client_id=design_reactor&response_type=code&scope=openid&state=map"
        //       );
        //       return true;
        //     }
        //   });
        // }
        if (config.ssoSettings && config.ssoSettings.length > 0) {
          let length = config.ssoSettings.length;
          let matchSSO = false;
          for (let i = 0; i < length; i++) {
            let item = config.ssoSettings[i];
            console.log("item", item);
            console.log("item.emailSuffix", item.emailSuffix);
            let emailSuffixArr = item.emailSuffix.split(",");
            let length = emailSuffixArr.length;
            for (i = 0; i < length; i++) {
              let settingEmail = emailSuffixArr[i];
              if (
                settingEmail &&
                _this.email.toLowerCase().indexOf(settingEmail) > -1
              ) {
                isSSO = true;
                // matchSSO = true;
                // window.location.replace(item.link);
                // window.location.replace(
                //   "https://sso-uat.arubanetworks.com/as/authorization.oauth2?redirect_uri=https://cx-app-aruba.appspot.com/m/sso&client_id=design_reactor&response_type=code&scope=openid&state=map"
                // );
                let ssoLink = config.ssoLink + "&state=map";
                window.location.replace(ssoLink);
                return true;
              }
            }
          }
          // email匹配不上ssoSetting的就跳转到带*号的，如果*号也没有，就是普通登录
          if (!matchSSO) {
            let arr = config.ssoSettings.filter((item) => {
              return item.emailSuffix === "*";
            });
            if (arr.length > 0) {
              let noMatchLink = arr[0].link;
              console.log("noMatchLink:", noMatchLink);
              isSSO = true;
              window.location.replace(noMatchLink);
              return true;
            }
          }
        } else {
          config.ssoSubffix.some((i) => {
            let sso = i.toLowerCase();
            if (_this.email.toLowerCase().indexOf(sso) > -1) {
              isSSO = true;
              window.location.replace(config.ssoLink);
              return true;
            }
          });
        }
        if (isSSO) {
          return;
        }

        this.$router
          .push({
            path: "/pages/login",
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (_this.isOpenCheckboxContainer == true) {
          if (this.checkbox === false && this.client != "PinterestCampus") {
            // this.$message.error('You must accept the Privacy Policy and Terms of Use to proceed.');
            this.$confirm({
              title: "Confirmation",
              message:
                "You must accept the Privacy Policy and Terms of Use to proceed",
              show: true,
            });
            return false;
          }
          if (this.email === "") {
            // this.$message.error('Please enter your email address');
            this.$confirm({
              title: "Confirmation",
              message: "Please enter your email address",
              show: true,
            });
            return false;
          }

          // let isSSO = false;
          // let ssoSubffix = JSON.parse(localStorage.config);
          // let client = localStorage.client;
          // if (client == 'Aruba') {
          //   ssoSubffix.ssoSubffix.some((i) => {
          //     if (_this.email.toLowerCase().indexOf(i) > -1) {
          //       isSSO = true
          //       window.location.replace("https://sso-uat.arubanetworks.com/as/authorization.oauth2?redirect_uri=https://cx-app-aruba.appspot.com/m/sso&client_id=design_reactor&response_type=code&scope=openid&state=map");
          //       return true;
          //     }
          //   })
          // } else {
          //   console.log("==== ssoSubffix=====", ssoSubffix)
          //   ssoSubffix.ssoSubffix.some((i) => {
          //     let sso = i.toLowerCase();
          //     if (_this.email.toLowerCase().indexOf(sso) > -1) {
          //       isSSO = true
          //       window.location.replace(ssoSubffix.ssoLink);
          //       return true;
          //     }
          //   })
          // }
          //
          // if (isSSO) {
          //   return
          // }

          let b = "";

          let url = "";
          let data = {};
          url = "m/check-email/";
          data.email = this.email.trim();
          this.bus.$emit("loading", true);
          // axios.defaults.withCredentials = false;
          axios
            .post(url, data)
            .then((response) => {
              console.log("loginEmail", response);
              if (response.data.result === "succeeded") {
                let isSSO = false;
                let config = JSON.parse(localStorage.config);
                let client = localStorage.client;
                // check-email 后再检查是否是sso账号
                // if (client == "Aruba") {
                //   config.ssoSubffix.some((i) => {
                //     let sso = i.toLowerCase();
                //     if (_this.email.toLowerCase().indexOf(sso) > -1) {
                //       isSSO = true;
                //       window.location.replace(
                //         "https://sso-uat.arubanetworks.com/as/authorization.oauth2?redirect_uri=https://cx-app-aruba.appspot.com/m/sso&client_id=design_reactor&response_type=code&scope=openid&state=map"
                //       );
                //       return true;
                //     }
                //   });
                // }
                if (config.ssoSettings && config.ssoSettings.length > 0) {
                  let length = config.ssoSettings.length;
                  let matchSSO = false;
                  for (let i = 0; i < length; i++) {
                    let item = config.ssoSettings[i];
                    console.log("item", item);
                    console.log("item.emailSuffix", item.emailSuffix);
                    let emailSuffixArr = item.emailSuffix.split(",");
                    let length = emailSuffixArr.length;
                    for (i = 0; i < length; i++) {
                      let settingEmail = emailSuffixArr[i];
                      if (
                        settingEmail &&
                        _this.email.toLowerCase().indexOf(settingEmail) > -1
                      ) {
                        isSSO = true;
                        matchSSO = true;
                        let link = item.link;
                        if (client == "Aruba") {
                          link = link += "&state=map";
                        }
                        window.location.replace(link);
                        return true;
                      }
                    }
                    // if (_this.email.toLowerCase().indexOf(item.emailSuffix) > -1) {
                    //   isSSO = true
                    //   matchSSO = true;
                    //   window.location.replace(item.link);
                    //   return true
                    // }else{
                    //   console.log('2222222',);
                    // }
                  }
                  // email匹配不上ssoSetting的就跳转到带*号的，如果*号也没有，就是普通登录
                  if (!matchSSO) {
                    let arr = config.ssoSettings.filter((item) => {
                      return item.emailSuffix === "*";
                    });
                    if (arr.length > 0) {
                      let noMatchLink = arr[0].link;
                      if (client == "Aruba") {
                        noMatchLink = noMatchLink += "&state=map";
                      }
                      console.log("noMatchLink:", noMatchLink);
                      isSSO = true;
                      window.location.replace(noMatchLink);
                      return true;
                    }
                  }
                } else {
                  config.ssoSubffix.some((i) => {
                    let sso = i.toLowerCase();
                    if (_this.email.toLowerCase().indexOf(sso) > -1) {
                      isSSO = true;
                      window.location.replace(config.ssoLink);
                      return true;
                    }
                  });
                }
                if (isSSO) {
                  return;
                }
                localStorage.userEmail = data.email;
                _this.$message.success("Success");
                _this.bus.$emit("loading", false);
                _this.$router
                  .push({
                    path: "/pages/login",
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                console.log("====response =====", response);
                _this.bus.$emit("loading", false);
                // _this.$confirm({title: "Confirmation", message: response.data.error, show: true})
                // let msg = response.data.error;
                if (localStorage.client === "WarnerMedia") {
                  _this.$confirm({
                    title: "Confirmation",
                    message:
                      "We're sorry, but it appears that your email address in not registered. Please try again or contact your manager for assistance.",
                    show: true,
                  });
                } else {
                  _this.$confirm({
                    title: "Confirmation",
                    message:
                      "We're sorry, but it appears that your email address in not registered. Please try again or contact your meeting/event organizer for assistance.",
                    show: true,
                  });
                }
                // _this.$message.error(response.data.error);
              }
            })
            .catch((err) => {
              console.table("err", err);
              _this.bus.$emit("loading", false);
              let msg = err.response.data.error;
              let condition =
                msg == "list index out of range" || msg == "" ? true : false;
              msg =
                condition == true
                  ? "We're sorry, but it appears that your email address in not registered. Please try again or contact your manager for assistance."
                  : msg;
              if (localStorage.client === "WarnerMedia") {
                _this.$confirm({
                  title: "Confirmation",
                  // message: "We're sorry, but it appears that your email address in not registered. Please try again or contact your manager for assistance.",
                  message: msg,
                  show: true,
                });
              } else if (localStorage.client === "NatWest") {
                let html = `<div>
                  If you are having trouble logging in, please visit <a target="_blank" href="https://rbos.service-now.com/myserviceportal?id=kb_article_view&sysparm_article=KB0055891">AskArchie</a> for assistance.  
                </div>`;
                _this.$confirm({
                  title: "Confirmation",
                  html,
                  show: true,
                });
              } else {
                _this.$confirm({
                  title: "Confirmation",
                  // message: "We're sorry, but it appears that your email address in not registered. Please try again or contact your meeting/event organizer for assistance.",
                  message: msg,
                  show: true,
                });
              }
            });
        } else {
          // if(emailRegular.test(this.email.trim())){
          //   this.$confirm({
          //     title: "Confirmation",
          //     message: 'Please enter a valid email address',
          //     show: true,
          //   });
          //   return
          // }
          new Promise((resolve, reject) => {
            _this.bus.$emit("loading", true);
            _this.multiRegionData.map((i, index) => {
              let url = "";
              let data = {};
              url = i.url + "/m/check-email/";
              // url = "https://sun-test2-dot-cx-app-metlife.uc.r.appspot.com/m/check-email/";
              data.email = this.email.trim();
              axios
                .post(url, data)
                .then((response) => {
                  console.log("data", response);
                  if (response.data.result == "succeeded") {
                    _this.haveErrorInRegion = true;
                    i.isCheck = false;
                    localStorage.userEmail = data.email;
                    console.log("yueueuy", localStorage.userEmail);

                    if (
                      JSON.stringify(_this.regionData).includes(
                        JSON.stringify(i)
                      ) === false
                    ) {
                      _this.regionData.push(i);
                      _this.regionData = _this.regionData.sort(function (a, b) {
                        return (a.name + "").localeCompare(b.name + "");
                      });
                    }
                  } else {
                    _this.errorMessageInRegion = response.data.error;
                    console.log(response.data, "0000088978iyt");
                  }
                  if (index + 1 == _this.multiRegionData.length) {
                    if (_this.regionData == 0) {
                      _this.haveErrorInRegion == true;
                    } else {
                      _this.haveErrorInRegion == false;
                    }
                    resolve(_this.regionData);
                  }
                })
                .catch((err) => {
                  console.log(err.response, "ppppp");
                  if (index + 1 == _this.multiRegionData.length) {
                    if (_this.regionData == 0) {
                      _this.haveErrorInRegion == true;
                      _this.errorMessageInRegion = err.response.data.error;
                      reject(err);
                    } else {
                      _this.haveErrorInRegion == false;
                      resolve(_this.regionData);
                    }
                  }
                });
            });
          })
            .then((data) => {
              console.log(data, "444", _this.errorMessageInRegion);
              _this.bus.$emit("loading", false);
              if (_this.haveErrorInRegion == true) {
                this.showInput = false;
                this.showSelectRegion = true;
                this.isOpenCheckboxContainer = true;
              } else {
                _this.$confirm({
                  title: "Confirmation",
                  message: _this.errorMessageInRegion,
                  show: true,
                });
              }

              // _this.regionData = _this.regionData.sort();
            })
            .catch((err) => {
              console.log(err);
              _this.bus.$emit("loading", false);
              this.$confirm({
                title: "Confirmation",
                message: _this.errorMessageInRegion,
                show: true,
              });
            });
        }
      }
    },
    firstCreateLoading() {
      localStorage.selectRegionSsoLink = "";
      localStorage.selectRegionLink = "";
      new Promise((resolve, reject) => {
        if (localStorage.config) {
          this.getClient();
          resolve("success");
        } else {
          this.getConfigReturn().then((res) => {
            localStorage.config = JSON.stringify(res.data);
            this.getClient();
            resolve("success");
          });
        }
      })
        .then((data) => {
          //处理成功后的逻辑
          if (
            JSON.parse(localStorage.config).multiRegion &&
            JSON.parse(localStorage.config).multiRegion.length > 0
          ) {
            this.isOpenMultiRegion = true;
            this.showRegionInfo = true;
            this.isOpenCheckboxContainer = false;
            this.multiRegionData = JSON.parse(localStorage.config).multiRegion;
            this.multiRegionData.map((i, index) => {
              if (i.name == "Asia") {
                i.url = "https://metworkday.com";
              } else {
                i.url = "https://sso-dot-cx-app-metlife-europe.appspot.com";
              }
            });
            console.log(this.multiRegionData, "shen");
          } else {
            this.isOpenMultiRegion = false;
            this.isOpenCheckboxContainer = true;
          }

          console.log(
            "config: ",
            JSON.parse(localStorage.config),
            this.isOpenMultiRegion
          ); //这个data 是接收的resolve参数--
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectRegionOption(item) {
      let _this = this;
      let url = "";
      this.bus.$emit("loading", true);
      // this.$store.state.contactSupport = 'https://my.metlife.com';
      // if(item.name == 'Asia'){
      //   this.$store.state.contactSupport = 'tel:0292661101';
      //   // localStorage.contactSupport = 'tel:0292661101';
      //   // _this.setContactSupport('tel:0292661101');
      // }else if(item.name == 'EMEA'){
      //   // _this.setContactSupport('tel:48223065152');
      //   this.$store.state.contactSupport = 'tel:48223065152';
      // }else{

      //   // _this.setContactSupport('');
      // }
      // console.log('sssss')
      // console.log(this.$store.state.contactSupport,'oooooooo');
      // console.log(item,this.regionData);
      this.regionData.map((i, index) => {
        // console.log(index);
        i.isCheck = false;
        if (i.name == item.name) {
          i.isCheck = true;
          _this.checkRegionName = item.name;
          localStorage.selectRegionLink = item.url;
          // localStorage.selectRegionLink = 'https://sun-test2-dot-cx-app-metlife.uc.r.appspot.com/';
          localStorage.selectRegionSsoLink = item.ssoLink;
          // localStorage.variableDomain = 'https://sun-test2-dot-cx-app-metlife.uc.r.appspot.com/';
          // url = "m/config/";

          url = localStorage.selectRegionLink + "/m/config/";
        }
        _this.$set(_this.regionData, index, i);
      });

      console.log(this.showSelectRegion, this.checkRegionName);

      axios
        .get(url)
        .then((response) => {
          // console.log('version', response);
          localStorage.config = JSON.stringify(response.data);
          _this.bus.$emit("loading", false);
        })
        .catch((err) => {
          console.log(err);
          _this.bus.$emit("loading", false);
        });
    },
  },
  created() {
    console.log("当前版本号：", process.env.version);
    this.firstCreateLoading();
  },
  mounted() {
    if (localStorage.ssoErrorMessage && localStorage.ssoErrorMessage != "") {
      let msg = localStorage.getItem("ssoErrorMessage");
      this.$confirm({ title: "Confirmation", message: msg, show: true })
        .then(() => {
          //用户点击确认后执行
          localStorage.removeItem("ssoErrorMessage");
        })
        .catch(() => {
          // 取消或关闭
          localStorage.removeItem("ssoErrorMessage");
        });
    }
  },
};
</script>
<style lang="scss" scoped>
#login {
  .loginBar {
    font-size: 18px;
  }
  .clorox {
    width: 340px;
  }

  .loginImg {
    width: 80px;
  }

  .natWestLogo {
    width: 200px;
  }
}
</style>
<style lang="scss">
.myCheckbox {
  margin-bottom: 10px;
  .v-input__slot {
    label {
      display: inline !important;
    }
  }
}
.checkboxContainer {
  display: flex;
  align-items: center;
  .customCheckbox {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
}
.selectRegion {
  text-align: left;
  p {
    font-size: 16px;
    cursor: pointer;
    .option {
      span {
        font-weight: bold;
      }
    }
    span {
      padding-left: 10px;
      color: #000;
    }
    .icon-gou {
      margin-left: 10px;
    }
  }
}
.regionInfo {
  float: left;
  width: calc(100% - 30px);
  text-align: left;
  padding-left: 10px;
  color: #000;
}
</style>

