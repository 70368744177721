import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import campusName from './store/campusName'
import meetingId from './store/meetingId'
import bookingType from './store/bookingType'
import mecToSetting from './store/mecToSetting'
import avator from './store/avator'

export default new Vuex.Store({
  state: {
    axiosArr: [], // save cancel token
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg',
    drawer: null,
    confidanielDomain:false,
    globalShowCheckIn:false,
    version: localStorage.getItem("version")
      ? JSON.parse(localStorage.getItem("version"))
      : {
        client: "",
        RED_HAT: "redhat",
        JUNIPER: "juniper",
        SILCOM: "silcom",
        HPE: "HPE",
        AVAYA: "avaya",
        PALO_ALTO_NETWORKS: "paloalto",
        WORKDAY: "workday",
        CISCOCXC: "Cisco CXC",
        EDWARDS: "Edwards Life Sciences",
        ORSTED: "Orsted",
      },
  },

  // venueName: localStorage.getItem("venueName")
  //   ? JSON.parse(localStorage.getItem("venueName"))
  //   : '',
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_SCRIM(state, payload) {
      state.barColor = payload
    },
    setClient(state, clientStr) {
      state.client = clientStr;
      // 保存到localStorage
      // localStorage.setItem("state",JSON.stringify(state));
    },
    setVenueName(state, venueName) {
      state.venueName = venueName;
      // 保存到localStorage
      // localStorage.setItem("state",JSON.stringify(state));
    },
    setConfidanielDomain(state, confidanielDomain){
      state.confidanielDomain = confidanielDomain;
    },
    setAxiosArr(state, cancelAjax) {
      state.axiosArr.push(cancelAjax.cancelToken);
    },
    clearAxiosArr(state) {
      state.axiosArr.forEach((item) => {
        console.log("item:", item);
        item();
      });
      state.axiosArr = [];
    },
  },
  actions: {

  },
  modules: {
    //模块引入
    campusName,//当前选择的campus
    meetingId,//当前选择的campus的id
    bookingType,//当前booking展示的类型
    mecToSetting,
    avator,//左侧导航栏头像
  }
})
