export default({
  namespaced: true,
  state: {
    mecToSetting: false
  },
  getters: {
    getMecToSetting: state => state.mecToSetting
  },
  mutations: {
    setMecToSetting: (state, data) => state.mecToSetting = data
  },
})

