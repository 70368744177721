<template>
  <div aria_label='loading'>
    <v-overlay v-show="loading" >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  export default {
    name: 'Loading',
    data: () => ({
      loading: false,
    }),
    created() {
      const that = this;
      this.bus.$on('loading', (data) => {
        that.loading = !!data;
      })
    }
  }
</script>

<style lang="scss">

</style>
