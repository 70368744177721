import $axios from 'axios';
import router from 'vue-router'
import confirm from '@/util/confirm'
import store from '../src/store'

$axios.interceptors.request.use(function (config) {
  if (localStorage.token) {
    let token = localStorage.token;
    config.headers['cx-token'] = token;
    config.headers['HTTP-CX-TOKEN'] = token;
   
    config.headers['cx-device-os'] = 'Android';//加这个token不会过期，profile和ga接口也需要这个header
  }
  if (localStorage.meetingIdMap) {
    config.headers['cx-meeting'] = localStorage.meetingIdMap;
  }
  // if(config.url.indexOf('http') > -1){
  //   console.log('have http');
  // }else{
    
  //   if(!localStorage.variableDomain){
  //     localStorage.variableDomain = localStorage.orginDomain;
  //   }
  //   config.url = localStorage.variableDomain + config.url;
  // }
  
  
  console.log('00000000',config.url); 
  // config.headers['content-type'] = 'application/x-www-from-urlencoded';
  config.method === 'post'?config.data = {...config.data}:config.params = {...config.data}
  config.cancelToken = new $axios.CancelToken(cancel => { // 在发送请求设置cancel token
    store.commit('setAxiosArr', { cancelToken: cancel })
  })
  return config;
}, function (error) {
  console.log("==== error=====", error)
  // 对请求错误做些什么
  return Promise.reject(error);
});

$axios.interceptors.response.use(function (response) {
  let that = this;
  // 处理响应数据
  if (response.status === 200) {
    // console.log("====response =====",response)
    if (response.data.error == 'Session timeout.') {
      if (!(window.location.href.indexOf('loginEmail') > -1)) {
        confirm({title: "Confirmation", message: 'Session timeout', show: true})
          .then(() => {
            localStorage.setItem("meetingIdMap", "");
            localStorage.setItem("token", "");
            //用户点击确认后执行
            if (process.env.NODE_ENV == 'development') {
              window.location.href = '/#/pages/loginEmail'
            } else {
              window.location.href = '/map#/pages/loginEmail'
            }
          }).catch(err => {
          localStorage.setItem("meetingIdMap", "");
          localStorage.setItem("token", "");
          if (process.env.NODE_ENV == 'development') {
            window.location.href = '/#/pages/loginEmail'
          } else {
            window.location.href = '/map#/pages/loginEmail'
          }
       })
      }
    } else {
      return Promise.resolve(response);
    }
  } else {
    return Promise.reject(response);
  }
}, function (error) {
  if (error.response.status === 401) {
    localStorage.setItem("unauthorized",true)
    if (!(window.location.href.indexOf('loginEmail') > -1)) {
      let msg = error.response.data.error || 'Unauthorized';
      confirm({title: "Confirmation", message: msg, show: true})
        .then(() => {
          console.log("==== process.env.NODE_ENV=====",process.env.NODE_ENV)
          //用户点击确认后执行
          if (process.env.NODE_ENV == 'development') {
            window.location.href = '/#/pages/loginEmail'
          } else {
            window.location.href = '/map#/pages/loginEmail'
          }
        }).catch(err => {
        if (process.env.NODE_ENV == 'development') {
          window.location.href = '/#/pages/loginEmail'
        } else {
          window.location.href = '/map#/pages/loginEmail'
        }
      })
      // 当页面中已经存在一个提示的弹窗时，后面就不再重复显示弹窗
      let popupLength = document.getElementsByClassName("v-overlay__scrim").length;
      if(popupLength>1){
        let length = popupLength;
        for(let i = 0; i < length; i++){
          document.getElementsByClassName("v-overlay")[i].style.display = 'none';
        }
        return
      }
    }
  }
  // 处理响应失败
  return Promise.reject(error);
});


