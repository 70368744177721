import Vue from 'vue'
import Router from 'vue-router'

// 批量引入文件（webpack提供的方法）
// const r = require.context('@/components', true, /\.vue$/)
// console.log('r',r);
// console.log('r.keys',r.keys());
// r.keys().forEach((filename)=>{
//   console.log('filename!!!!!!!!!!!!!!!!!',filename);
//   const nameArr = filename.split(".")
//   console.log('nameArr',nameArr);
//   console.log('r(filename).default:',r(filename).default);
// })

Vue.use(Router)

import LoginEmail from "@/views/pages/LoginEmail";

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/pages',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: 'Lock',
          path: 'lock',
          component: () => import('@/views/pages/Lock')
        },
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/views/pages/Login')
        },
        {
          name: 'LoginEmail',
          path: 'loginEmail/:meetingIdMap?',
          component: LoginEmail
          // component: () => import('@/views/pages/LoginEmail')
        },
        // {
        //   name: 'Pricing',
        //   path: 'pricing',
        //   component: () => import('@/views/pages/Pricing')
        // },
        // {
        //   name: 'Register',
        //   path: 'register',
        //   component: () => import('@/views/pages/Register')
        // }
      ]
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [

        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard')
        },
        //MEC
        {
          name: 'mec',
          path: 'mec/:meetingIdMap?',
          component: () => import('@/views/dashboard/spaces/mec')
        },
        // Booking
        {
          name: 'booking',
          path: 'booking',
          component: () => import('@/views/dashboard/spaces/booking')
        },
        // BookingMap
        {
          name: 'bookingMap',
          path: 'spaces/bookingMap/:meetingId?',
          component: () => import('@/views/dashboard/spaces/bookingMap')
        },
        // Pages
        // {
        //   name: 'search',
        //   path: 'spaces/search',
        //   component: () => import('@/views/dashboard/spaces/Search')
        // },
        {
          name: 'Spaces',
          path: 'spaces',
          component: () => import('@/views/dashboard/spaces/Spaces')
        },
        {
          name: 'Setting',
          path: 'setting',
          component: () => import('@/views/dashboard/spaces/setting')
        },
        {
          name: 'Directory',
          path: 'directory',
          component: () => import('@/views/dashboard/spaces/directory')
        },
        {
          name: 'Iframe',
          path: 'iframe',
          component: () => import('@/views/dashboard/spaces/iframe')
        },
        {
          name: 'GlobalDirectory',
          path: 'globalDirectory',
          component: () => import('@/views/dashboard/spaces/globalDirectory')
        },
      ],
      meta: {requiresAuth: true} // 添加表示需要验证
    },
    // {
    //   path: '/',
    //   component: () => import('@/views/pages/Welcome'),
    //   meta: { requiresAuth: true }
    // },
    {
      path: '*',
      component: () => import('@/views/pages/Index'),
      children: [
        {
          name: '404 Error',
          path: '',
          component: () => import('@/views/pages/Error')
        }
      ]
    }
  ]
})
